// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---plugins-i-18-n-paths-src-redirect-js": () => import("./../../../plugins/i18n-paths/src/redirect.js" /* webpackChunkName: "component---plugins-i-18-n-paths-src-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avi-standard-of-quality-js": () => import("./../../../src/pages/AVI-standard-of-quality.js" /* webpackChunkName: "component---src-pages-avi-standard-of-quality-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/FAQ.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-science-js": () => import("./../../../src/pages/our-science.js" /* webpackChunkName: "component---src-pages-our-science-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-payment-confirmation-js": () => import("./../../../src/pages/paymentConfirmation.js" /* webpackChunkName: "component---src-pages-payment-confirmation-js" */),
  "component---src-pages-sierra-nevada-js": () => import("./../../../src/pages/sierra-nevada.js" /* webpackChunkName: "component---src-pages-sierra-nevada-js" */),
  "component---src-pages-store-locator-js": () => import("./../../../src/pages/store-locator.js" /* webpackChunkName: "component---src-pages-store-locator-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-unsubscribe-newsletter-js": () => import("./../../../src/pages/unsubscribe-newsletter.js" /* webpackChunkName: "component---src-pages-unsubscribe-newsletter-js" */),
  "component---src-templates-product-line-js": () => import("./../../../src/templates/productLine.js" /* webpackChunkName: "component---src-templates-product-line-js" */),
  "component---src-templates-productdetail-js": () => import("./../../../src/templates/productdetail.js" /* webpackChunkName: "component---src-templates-productdetail-js" */)
}

